<template>
  <div class="login">
    <h1 class="title">后台管理</h1>
    <div class="title-hr"></div>
    <h4 class="title-describe">----------</h4>
    <div class="login-container">
      <el-input
        class="login-input"
        v-model="global.admin"
        placeholder="请输入后台管理密码："
        show-password
      ></el-input>
      <el-button class="submit" @click="login(global.admin)">登录</el-button>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui'
export default {
  data() {
    return {
      global: {
        admin: ''
      }
    }
  },
  methods: {
    login(admin) {
      this.$http
        .post('/api/checkAdmin', {
          admin: admin
        })
        .then((response) => {
          if (response.data != '00' && response.data != null) {
            let token = JSON.stringify(response.data)
            localStorage.setItem('token', token)
            Message.success('登录成功')
            this.$router.push('/admin')
          } else {
            Message.error('登录失败')
          }
        })
    }
  }
}
</script>

<style>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 60px;
  height: 80px;
  margin: 40px;
}

.login-input {
  margin-right: 20px;
  width: 300px;
}

.login-input > .el-input__inner:hover,
.login-input > .el-input__inner:focus {
  border: 2px #ffe0b7 solid;
}

.submit {
  width: 80px;
  background-color: #ffe0b7;
  color: #666666;
  border-color: #00000000;
}

.submit:focus,
.submit:hover {
  color: #ffe0b7;
  border-color: #00000000;
  background-color: #666666;
}

.submit:active {
  background-color: #000000;
}
</style>
