<template>
  <div class="admin-section-container">
    <el-form label-width="100px" class="dynamic">
      <el-form-item>
        <span class="input-title h2">合作伙伴页面 > 二级标题内容：</span>
        <div class="input-content title">
          <el-input v-model="global.partnersTitle"></el-input>
          <el-button
            class="title-change"
            type="primary"
            @click.prevent="savePartnersTitle()"
            >保存</el-button
          >
        </div>
      </el-form-item>
      <el-form-item v-for="(partner, index) in partners" :key="partner.id">
        <span class="input-title h2">合作伙伴{{ index + 1 }}：</span>
        <div
          class="input-content"
          style="display: flex; justify-content: center; align-items: center"
        >
          <img
            :ref="`upload${partner.id}`"
            :src="partner.img"
            style="width: 200px"
          />
        </div>
        <div class="input-content">
          <el-upload
            drag
            :show-file-list="false"
            :action="url"
            :headers="{
              Authorization: token
            }"
            accept="image/*"
            :on-success="
              (response, file, fileList) =>
                show(partner, response, file, fileList)
            "
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
        </div>
        <div class="input-content upload">
          <span class="input-title originator">介绍：</span>
          <el-input
            type="textarea"
            :autosize="{ minRows: 2 }"
            v-model="partner.intro"
          ></el-input>
        </div>
        <div class="input-content upload">
          <span class="input-title originator">链接：</span>
          <el-input v-model="partner.url"></el-input>
        </div>
        <div class="input-content upload">
          <el-button type="primary" @click.prevent="saveDomain(partner)"
            >保存</el-button
          ><el-button type="danger" @click.prevent="removeDomain(partner)"
            >删除</el-button
          >
        </div>
      </el-form-item>
      <el-form-item class="addreset upload">
        <el-button round type="warning" @click="addDomain">新增</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Message } from 'element-ui'
import axios from '@/api/http'
export default {
  data() {
    return {
      url: '/api/upload',
      global: {},
      token: JSON.parse(localStorage.getItem('token')),
      partners: []
    }
  },

  created: function () {
    console.log(this.url)

    this.$http.get('/api/global').then((response) => {
      this.global = response.data
    })

    this.$http.get('/api/partners').then((response) => {
      this.partners = response.data
    })
  },

  methods: {
    show(partner, response) {
      console.log(partner, response)
      partner.img = '/api/' + response
    },

    savePartnersTitle() {
      this.$http
        .put('/api/global', {
          partnersTitle: this.global.partnersTitle
        })
        .then((response) => {
          if (response.data == 1) {
            Message.success('修改成功')
          } else {
            Message.error('修改失败')
          }
        })
    },

    saveDomain(item) {
      this.$http.put('/api/partners', item).then((response) => {
        if (response.data == 1) {
          Message.success('修改成功')
        } else if (response.data == 11) {
          Message.success('新增成功')
        } else {
          Message.error('失败')
        }
      })
    },

    removeDomain(item) {
      var index = this.partners.indexOf(item)
      if (index !== -1) {
        this.partners.splice(index, 1)
        this.$http.delete('/api/partners/' + item.id).then((response) => {
          if (response.data == 1) {
            Message.success('删除成功')
          } else {
            Message.error('删除失败')
          }
        })
      }
    },

    addDomain() {
      this.partners.push({
        img: ''
      })
    }
  }
}
</script>

<style>
.input-content.upload {
  width: 100%;
}
</style>
