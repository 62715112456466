<template>
  <div class="admin-section-container">
    <el-form label-width="100px" class="dynamic">
      <el-form-item>
        <span class="input-title h2">创始人页面 > 二级标题内容：</span>
        <div class="input-content title">
          <el-input v-model="global.originatorTitle"></el-input>
          <el-button
            class="title-change"
            type="primary"
            @click.prevent="saveOriginatorTitle()"
            >保存</el-button
          >
        </div>
      </el-form-item>
      <el-form-item>
        <span class="input-title h2">名誉榜页面 > 二级标题内容：</span>
        <div class="input-content title">
          <el-input v-model="global.honorListTitle"></el-input>
          <el-button
            class="title-change"
            type="primary"
            @click.prevent="saveHonorListTitle()"
            >保存</el-button
          >
        </div>
      </el-form-item>
      <el-form-item>
        <span class="input-title h2">创始人：</span>
        <div class="input-content">
          <span class="input-title originator">QQ：</span>
          <el-input v-model="originator.qq"></el-input>
        </div>
        <div class="input-content">
          <span class="input-title originator">呼号：</span>
          <el-input v-model="originator.number"></el-input>
        </div>
        <div class="input-content" style="width: 100%">
          <span class="input-title originator">介绍：</span>
          <el-input v-model="originator.intro"></el-input>
        </div>
        <div class="input-content originator">
          <el-button type="primary" @click.prevent="saveDomain(originator)"
            >保存</el-button
          >
          <el-button disabled class="title-change" type="danger"
            >删除</el-button
          >
        </div>
      </el-form-item>
      <el-form-item v-for="(user, index) in users" :key="user.id">
        <span class="input-title h2">名誉榜成员{{ index + 1 }}：</span>
        <div class="input-content">
          <span class="input-title originator">QQ：</span>
          <el-input v-model="user.qq"></el-input>
        </div>
        <div class="input-content">
          <span class="input-title originator">呼号：</span>
          <el-input v-model="user.number"></el-input>
        </div>
        <div class="input-content" style="width: 100%">
          <span class="input-title originator">介绍：</span>
          <el-input v-model="user.intro"></el-input>
        </div>
        <div class="input-content">
          <el-button type="primary" @click.prevent="saveDomain(user)"
            >保存</el-button
          ><el-button type="danger" @click.prevent="removeDomain(user)"
            >删除</el-button
          >
        </div>
      </el-form-item>
      <el-form-item class="addreset">
        <el-button round type="warning" @click="addDomain">新增</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Message } from 'element-ui'
export default {
  data() {
    return {
      global: {},
      originator: {},
      users: []
    }
  },

  created: function () {
    this.$http.get('/api/global').then((response) => {
      this.global = response.data
    })

    this.$http.get('/api/users/one').then((response) => {
      this.originator = response.data
    })

    this.$http.get('/api/users/all').then((response) => {
      this.users = response.data
    })
  },

  methods: {
    saveOriginatorTitle() {
      this.$http
        .put('/api/global', {
          originatorTitle: this.global.originatorTitle
        })
        .then((response) => {
          if (response.data == 1) {
            Message.success('修改成功')
          } else {
            Message.error('修改失败')
          }
        })
    },

    saveHonorListTitle() {
      this.$http
        .put('/api/global', {
          honorListTitle: this.global.honorListTitle
        })
        .then((response) => {
          if (response.data == 1) {
            Message.success('修改成功')
          } else {
            Message.error('修改失败')
          }
        })
    },

    saveDomain(item) {
      if (item.groupId !== 1) {
        item.groupId = 0
      }
      this.$http.put('/api/users', item).then((response) => {
        if (response.data == 1) {
          Message.success('修改成功')
        } else if (response.data == 11) {
          Message.success('新增成功')
        } else {
          Message.error('失败')
        }
      })
    },

    removeDomain(item) {
      var index = this.users.indexOf(item)
      if (index !== -1) {
        this.users.splice(index, 1)
        this.$http.delete('/api/users/' + item.id).then((response) => {
          if (response.data == 1) {
            Message.success('删除成功')
          } else {
            Message.error('删除失败')
          }
        })
      }
    },
    addDomain() {
      this.users.push({
        qq: ''
      })
    }
  }
}
</script>

<style>
.input-title.originator {
  width: 60px;
}
</style>
