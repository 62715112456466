import VueRouter from 'vue-router'
import sponsor from '@/components/sponsor'
import index from '@/components/index'
import partners from '@/components/partners'
import login from '@/components/login'
import admin from '@/components/admin'
import adminFeatures from '@/components/adminFeatures'
import adminOriginator from '@/components/adminOriginator'
import intros from '@/components/adminIntros'
import adminPartners from '@/components/adminPartners'
import adminFooter from '@/components/adminFooter'
import saveAdmin from '@/components/saveAdmin'

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: index,
      meta: {
        title: '空管模拟机交流官网-首页'
      }
    },
    {
      path: '/sponsor',
      component: sponsor,
      meta: {
        title: '空管模拟机交流官网-支持我们'
      }
    },
    {
      path: '/partners',
      component: partners,
      meta: {
        title: '空管模拟机交流官网-合作伙伴'
      }
    },
    {
      path: '/login',
      component: login,
      meta: {
        title: 'ATCSC后台-登录'
      }
    },
    {
      path: '/admin',
      component: admin,
      redirect: '/admin/features',
      children: [
        {
          path: 'features',
          component: adminFeatures,
          meta: {
            title: 'ATCSC后台-本群特点'
          }
        },
        {
          path: 'originator',
          component: adminOriginator,
          meta: {
            title: 'ATCSC后台-创始人&名誉榜'
          }
        },
        {
          path: 'intros',
          component: intros,
          meta: {
            title: 'ATCSC后台-群简介'
          }
        },
        {
          path: 'adminpartners',
          component: adminPartners,
          meta: {
            title: 'ATCSC后台-合作伙伴'
          }
        },
        {
          path: 'footer',
          component: adminFooter,
          meta: {
            title: 'ATCSC后台-网站底栏'
          }
        },
        {
          path: 'saveadmin',
          component: saveAdmin,
          meta: {
            title: 'ATCSC后台-更改密码'
          }
        }
      ]
    }
  ]
})
export default router

router.beforeEach((to, from, next) => {
  const tk = JSON.parse(localStorage.getItem('token'))

  document.title = to.meta.title

  const writeList = ['/', '/sponsor', '/partners', '/login']

  if (!tk) {
    if (writeList.indexOf(to.path) !== -1) {
      next()
    }
    next(false)
  }

  if (tk) {
    if (to.path === '/login') {
      next('/admin')
    }
    next()
  }
})
