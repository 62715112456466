<template>
  <div class="admin-section-container">
    <el-form label-width="100px" class="dynamic">
      <el-form-item>
        <span class="input-title h2">本群特点页面 > 二级标题内容：</span>
        <div class="input-content title">
          <el-input v-model="global.featuresTitle"></el-input>
          <el-button
            class="title-change"
            type="primary"
            @click="saveFeaturesTitle()"
            >保存</el-button
          >
        </div>
      </el-form-item>
      <el-form-item v-for="(featrue, index) in features" :key="featrue.id">
        <div class="input-content" style="width: 100%">
          <span class="input-title h2">群特点{{ index + 1 }}</span>
        </div>
        <div class="input-content">
          <span class="input-title">标题：</span>
          <el-input v-model="featrue.title"></el-input>
        </div>
        <div class="input-content">
          <span class="input-title">内容：</span>
          <el-input v-model="featrue.detail"></el-input>
        </div>
        <div class="input-content">
          <el-button type="primary" @click.prevent="saveDomain(featrue)"
            >保存</el-button
          ><el-button type="danger" @click.prevent="removeDomain(featrue)"
            >删除</el-button
          >
        </div>
      </el-form-item>
      <el-form-item class="addreset">
        <el-button round type="warning" @click="addDomain">新增</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Message } from 'element-ui'
export default {
  data() {
    return {
      global: {},
      features: []
    }
  },
  created: function () {
    this.$http.get('/api/global').then((response) => {
      this.global = response.data
    })

    this.$http.get('/api/features').then((response) => {
      this.features = response.data
    })
  },
  methods: {
    saveFeaturesTitle() {
      this.$http
        .put('/api/global', { featuresTitle: this.global.featuresTitle })
        .then((response) => {
          if (response.data == 1) {
            Message.success('修改成功')
          } else {
            Message.error('修改失败')
          }
        })
    },

    saveDomain(item) {
      this.$http.put('/api/features', item).then((response) => {
        if (response.data == 1) {
          Message.success('修改成功')
        } else if (response.data == 11) {
          Message.success('新增成功')
        } else {
          Message.error('失败')
        }
      })
    },

    removeDomain(item) {
      var index = this.features.indexOf(item)
      if (index !== -1) {
        this.features.splice(index, 1)
        this.$http.delete('/api/features/' + item.id).then((response) => {
          if (response.data == 1) {
            Message.success('删除成功')
          } else {
            Message.error('删除失败')
          }
        })
      }
    },
    addDomain() {
      this.features.push({
        title: ''
      })
    }
  }
}
</script>

<style>
.admin-section-container {
  padding: 60px 230px;
}

.el-form-item__content {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0 !important;
  border-radius: 4px;
  padding: 10px;
  /* background-color: #ffe0b725; */
}

.input-title {
  width: 60px;
  font-weight: 600;
}

.input-content {
  width: 50%;
  display: flex;
  padding: 15px 10px 15px 10px;
}

.input-title.h2 {
  width: 100%;
  font-size: 14px;
}

.input-content.title {
  width: 100%;
  padding: 0;
  margin: 10px 0 0;
}

.title-change {
  margin: 0 10px;
}

.admin-users-container {
  padding: 60px 80px;
}

.addreset > div > .el-button {
  width: 240px;
  margin: auto;
  font-weight: 600;
}
</style>
