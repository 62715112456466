<template>
  <div class="feature-container">
    <el-card class="feature-card" style="width: 100%">
      <h4>{{ name }}</h4>
      <p style="font-size: 15px; color: #474747">{{ detail }}</p>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ["name", "detail"],
};
</script>

<style>
.feature-container {
  height: 82px;
  width: 340px;
  line-height: 25px;
  margin: 0 auto 40px;
}
.el-card {
  border: none;
}

.feature-card.is-always-shadow {
  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 10%);
}
</style>