<template>
  <div class="admin-section-container">
    <el-form label-width="100px" class="dynamic">
      <el-form-item>
        <span class="input-title h2">赞助页面 > 二级标题内容：</span>
        <div class="input-content title">
          <el-input v-model="global.sponsorTitle"></el-input>
          <el-button
            class="title-change"
            type="primary"
            @click.prevent="saveSponsorTitle()"
            >保存</el-button
          >
        </div>
      </el-form-item>
      <el-form-item>
        <span class="input-title h2">网站底栏 > 版权信息：</span>
        <div class="input-content title">
          <el-input v-model="global.copyright"></el-input>
          <el-button
            class="title-change"
            type="primary"
            @click.prevent="saveCopyright()"
            >保存</el-button
          >
        </div>
      </el-form-item>
      <el-form-item>
        <span class="input-title h2">网站底栏 > 备案号：</span>
        <div class="input-content title">
          <el-input v-model="global.record"></el-input>
          <el-button
            class="title-change"
            type="primary"
            @click.prevent="saveRecord()"
            >保存</el-button
          >
        </div>
      </el-form-item>
      <el-form-item v-for="(link, index) in links" :key="link.id">
        <span class="input-title h2">友情链接{{ index + 1 }}：</span>
        <div class="input-content">
          <span class="input-title">标题：</span>
          <el-input v-model="link.title"></el-input>
        </div>
        <div class="input-content">
          <span class="input-title">链接：</span>
          <el-input v-model="link.url"></el-input>
        </div>
        <div class="input-content">
          <el-button type="primary" @click.prevent="saveDomain(link)"
            >保存</el-button
          ><el-button type="danger" @click.prevent="removeDomain(link)"
            >删除</el-button
          >
        </div>
      </el-form-item>
      <el-form-item class="addreset">
        <el-button round type="warning" @click="addDomain">新增</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Message } from 'element-ui'
export default {
  data() {
    return {
      global: {},
      links: []
    }
  },

  created: function () {
    this.$http.get('/api/global').then((response) => {
      this.global = response.data
    })

    this.$http.get('/api/links').then((response) => {
      this.links = response.data
    })
  },

  methods: {
    saveSponsorTitle() {
      this.$http
        .put('/api/global', {
          sponsorTitle: this.global.sponsorTitle
        })
        .then((response) => {
          if (response.data == 1) {
            Message.success('修改成功')
          } else {
            Message.error('修改失败')
          }
        })
    },

    saveCopyright() {
      this.$http
        .put('/api/global', {
          copyright: this.global.copyright
        })
        .then((response) => {
          if (response.data == 1) {
            Message.success('修改成功')
          } else {
            Message.error('修改失败')
          }
        })
    },

    saveRecord() {
      this.$http
        .put('/api/global', {
          record: this.global.record
        })
        .then((response) => {
          if (response.data == 1) {
            Message.success('修改成功')
          } else {
            Message.error('修改失败')
          }
        })
    },

    saveDomain(item) {
      this.$http.put('/api/links', item).then((response) => {
        if (response.data == 1) {
          Message.success('修改成功')
        } else if (response.data == 11) {
          Message.success('新增成功')
        } else {
          Message.error('失败')
        }
      })
    },

    removeDomain(item) {
      var index = this.links.indexOf(item)
      if (index !== -1) {
        this.links.splice(index, 1)
        this.$http.delete('/api/links/' + item.id).then((response) => {
          if (response.data == 1) {
            Message.success('删除成功')
          } else {
            Message.error('删除失败')
          }
        })
      }
    },
    addDomain() {
      this.links.push({
        title: ''
      })
    }
  }
}
</script>

<style></style>
