<template>
  <div class="save-admin-container">
    <div class="save-admin-content">
      <el-input
        class="save-admin-input"
        v-model="admin.oldAdmin"
        placeholder="请输入旧密码："
        show-password
      ></el-input>
    </div>
    <div class="save-admin-content">
      <el-input
        class="save-admin-input"
        v-model="admin.newAdmin"
        placeholder="请输入新密码："
        show-password
      ></el-input>
      <el-button class="submit" @click="saveAdmin(admin)">保存</el-button>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui'
export default {
  data() {
    return {
      admin: {
        oldAdmin: '',
        newAdmin: ''
      }
    }
  },

  methods: {
    saveAdmin(admin) {
      this.$http.post('/api/saveAdmin', admin).then((response) => {
        if (response.data) {
          Message.success('修改成功')
          localStorage.clear()
          this.$router.push('/login')
        } else {
          Message.error('修改失败')
        }
      })
    }
  }
}
</script>

<style>
.save-admin-content {
  margin: 30px 0 0px;
}

.save-admin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30vh;
}

.save-admin-input {
  margin-right: 20px;
  width: 300px;
}

.save-admin-input > .el-input__inner:hover,
.save-admin-input > .el-input__inner:focus {
  border: 2px #ffe0b7 solid;
}

.submit {
  font-weight: 600;
  width: 80px;
  background-color: #ffe0b7;
  color: #666666;
  border-color: #00000000;
}

.submit:focus,
.submit:hover {
  color: #ffe0b7;
  border-color: #00000000;
  background-color: #666666;
}

.submit:active {
  background-color: #000000;
}
</style>
