<template>
  <div class="admin-container">
    <el-menu
      :default-active="$route.path"
      mode="horizontal"
      background-color="#000000"
      text-color="#ffffff"
      active-text-color="#ffe0b7"
      :router="true"
      style="position: fixed; width: 100%; z-index: 99"
    >
      <el-menu-item index="/admin/features">本群特点</el-menu-item>
      <el-menu-item index="/admin/intros">群简介</el-menu-item>
      <el-menu-item index="/admin/originator">创始人&名誉榜</el-menu-item>
      <el-menu-item index="/admin/adminpartners">合作伙伴</el-menu-item>
      <el-menu-item index="/admin/footer">网站底栏</el-menu-item>
      <el-submenu index="6">
        <template slot="title">其他</template>
        <el-menu-item index="/admin/saveadmin">更改密码</el-menu-item>
        <el-menu-item @click="logout()" id="logout">注销</el-menu-item>
      </el-submenu>
    </el-menu>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push("/");
    },
  },
};
</script>


<style>
.el-submenu__title,
.el-menu-item {
  font-weight: 600;
}

.el-menu--horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 3px solid #ffe0b7;
}

#logout:hover {
  background-color: red !important;
  color: #ffffff !important;
}

.el-menu--popup {
  padding: 0;
}

.el-menu-item:hover {
  background-color: #ffe0b7 !important;
  color: #000000 !important;
}
</style>                                                                                 