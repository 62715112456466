import axios from 'axios'

axios.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    const token = JSON.parse(localStorage.getItem("token"))
    if (token) {
        config.headers.Authorization = token;
    }
    return config;
})

export default axios;