<template>
  <div class="user-container">
    <el-card style="width: 100%; height: 100%">
      <img
        class="portrait"
        :src="`http://q2.qlogo.cn/headimg_dl?dst_uin=${qq}&spec=640`"
      />
      <p class="id">{{ number }}</p>
      <p class="qq">
        QQ：<span>{{ qq }}</span>
      </p>
      <div class="user-intro">
        {{ intro }}
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ['qq', 'number', 'intro']
}
</script>

<style>
.user-container {
  width: 360px;
  height: 530px;
  margin: 0 auto 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portrait {
  display: block;
  width: 250px;
  margin: 30px auto 40px;
}

.id,
.qq {
  text-align: center;
  display: block;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  color: #646464;
}

.user-intro {
  margin-top: 10px;
  font-size: 15px;
  text-align: center;
  padding: 0px 30px 0;
  color: #9e9e9e;
  height: 80px;
  overflow-y: auto;
}

.user-intro::-webkit-scrollbar {
  width: 0;
}

.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: 0px 0px 30px 10px rgb(0 0 0 / 10%);
}
</style>
