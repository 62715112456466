<template>
  <div class="partners-container">
    <el-page-header
      @back="goBack"
      style="position: fixed; top: 20px; left: 40px"
    >
    </el-page-header>
    <h1 class="title">合作伙伴</h1>
    <div class="title-hr"></div>
    <h4 class="title-describe">----------</h4>
    <div class="partner-context">
      <!-- <div class="partner-container">
        <el-carousel indicator-position="outside" height="460px" type="card">
          <el-carousel-item v-for="partner in partners" :key="partner.id">
            <img class="partner-img" :src="partner.img" />
            <p class="partner-intro">{{ partner.intro }}</p>
            <p class="partner-deal">{{ partner.deal }}</p>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="partner-container-1">
        <el-carousel indicator-position="outside" height="400px">
          <el-carousel-item v-for="partner in partners" :key="partner.id">
            <img class="partner-img" :src="partner.img" />
            <p class="partner-intro">{{ partner.intro }}</p>
            <p class="partner-deal">{{ partner.deal }}</p>
          </el-carousel-item>
        </el-carousel>
      </div> -->
      <partner
        v-for="partner in partners"
        :key="partner.id"
        :img="partner.img"
        :intro="partner.intro"
        :url="partner.url"
      ></partner>
    </div>
  </div>
</template>

<script>
import partner from '@/components/partner'

export default {
  components: {
    partner
  },
  data() {
    return {
      partners: []
    }
  },
  created: function () {
    this.$http.get('/api/partners').then((response) => {
      this.partners = response.data
    })
  },
  methods: {
    goBack() {
      this.$router.push('/')
    }
  }
}
</script>

<style>
.partner-context {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 60px;
}

.partners-container {
  padding-bottom: 60px;
}

@media screen and (max-width: 750px) {
  .partner-context {
    padding: 0px 0px;
  }

  .partner-container > div > div {
    width: 320px;
    height: 280px;
  }
  .partner-intro {
    padding: 15px 0 0;
  }

  .partner-portrait {
    max-width: 280px;
  }
}

/* .partner-container {
  padding: 40px 100px 0;
  width: 100%;
  position: relative;
}

.partner-container-1 {
  padding: 40px 100px 0;
  width: 100%;
  opacity: 0;
  position: absolute;
  top: -50px;
}

@media screen and (max-width: 768px) {
  .partner-container {
    opacity: 0;
  }

  .partner-container-1 {
    opacity: 1;
    top: 0;
  }
}

.partner-img {
  display: block;
  max-width: 40%;
  margin: 40px auto 20px;
  max-height: 40%;
}

.partner-intro {
  width: 100%;
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}

.partner-deal {
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-bottom: 40px;
}

.el-carousel__item:nth-child(n) {
  background-color: #404040;
  color: aliceblue;
} */
</style>
