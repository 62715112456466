<template>
  <div class="link"><span> > </span><el-link :underline="false" :href="url" target="_blank">{{ title}}</el-link></div>
</template>

<script>
export default {
    props:["url","title"]
};
</script>

<style>
.link {
  font-size: 14px;
  line-height: 38px;
  border-bottom: 1px #666666 solid;
}

.el-link.el-link--default {
    color: #ffffff;
}

.el-link.el-link--default:hover{
  color: rgb(255, 230, 199);
}

</style>