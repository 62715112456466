<template>
  <div class="partner-container">
    <el-card style="width: 100%; height: 100%">
      <img class="partner-portrait" :src="img" />
      <div class="partner-intro">
        <p>{{ intro }}</p>
      </div>
      <div class="partner-more">
        <el-link :underline="false" :href="url" target="_blank">
          <p class="partner-more-text">More About Us ?</p>
        </el-link>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ["img", "intro", "url"],
};
</script>

<style>
.partner-container{
  margin: 60px auto 0;
}

.partner-container > div > div {
  width: 600px;
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.partner-portrait {
  max-width: 480px;
  max-height: 230px;
  display: block;
  margin: 20px auto 0;
}

.partner-intro {
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 15px 30px 0;
  font-size: 16px;
  color: #9e9e9e;
  overflow: auto;
  line-height: 20px;
  text-indent: 5px;
}

.partner-intro::-webkit-scrollbar {
  width: 0;
}


.partner-more {
  background-color: rgba(0, 0, 0, 0);
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  opacity: 0%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.partner-more:hover {
  height: 100%;
  opacity: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.6s ease;
}

.partner-more-text {
  font-size: 30px;
  letter-spacing: 3px;
  font-weight: 600;
}
</style>