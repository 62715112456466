<template>
  <div class="admin-section-container">
    <el-form label-width="100px" class="dynamic">
      <el-form-item>
        <span class="input-title h2">群简介页面 > 二级标题内容：</span>
        <div class="input-content title">
          <el-input v-model="global.introsTitle"></el-input>
          <el-button
            class="title-change"
            type="primary"
            @click.prevent="saveIntrosTitle()"
            >保存</el-button
          >
        </div>
      </el-form-item>
      <el-form-item>
        <span class="input-title h2">群简介内容：</span>
        <div class="input-content title">
          <el-input
            :autosize="{ minRows: 3 }"
            type="textarea"
            v-model="global.introsIntro"
          ></el-input>
          <el-button
            class="title-change"
            type="primary"
            @click.prevent="saveIntrosIntro()"
            style="height: 40px"
            >保存</el-button
          >
        </div>
      </el-form-item>
      <el-form-item v-for="(intro, index) in intros" :key="intro.key">
        <div class="input-content" style="width: 100%">
          <span class="input-title intros">文案{{ index + 1 }}：</span>
          <el-input v-model="intro.copy"></el-input>
        </div>
        <div class="input-content">
          <el-button type="primary" @click.prevent="saveDomain(intro)"
            >保存</el-button
          ><el-button type="danger" @click.prevent="removeDomain(intro)"
            >删除</el-button
          >
        </div>
      </el-form-item>
      <el-form-item class="addreset">
        <el-button round type="warning" @click="addDomain">新增</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Message } from 'element-ui'
export default {
  data() {
    return {
      global: {},
      intros: []
    }
  },

  created: function () {
    this.$http.get('/api/global').then((response) => {
      this.global = response.data
    })

    this.$http.get('/api/intros').then((response) => {
      this.intros = response.data
    })
  },

  methods: {
    saveIntrosTitle() {
      this.$http
        .put('/api/global', {
          introsTitle: this.global.introsTitle
        })
        .then((response) => {
          if (response.data == 1) {
            Message.success('修改成功')
          } else {
            Message.error('修改失败')
          }
        })
    },

    saveIntrosIntro() {
      this.$http
        .put('/api/global', {
          introsIntro: this.global.introsIntro
        })
        .then((response) => {
          if (response.data == 1) {
            Message.success('修改成功')
          } else {
            Message.error('修改失败')
          }
        })
    },

    saveDomain(item) {
      this.$http.put('/api/intros', item).then((response) => {
        if (response.data == 1) {
          Message.success('修改成功')
        } else if (response.data == 11) {
          Message.success('新增成功')
        } else {
          Message.error('失败')
        }
      })
    },

    removeDomain(item) {
      var index = this.intros.indexOf(item)
      if (index !== -1) {
        this.intros.splice(index, 1)
        this.$http.delete('/api/intros/' + item.id).then((response) => {
          if (response.data == 1) {
            Message.success('删除成功')
          } else {
            Message.error('删除失败')
          }
        })
      }
    },
    addDomain() {
      this.intros.push({
        copy: ''
      })
    }
  }
}
</script>

<style>
.input-title.intros {
  width: 70px;
}
</style>
