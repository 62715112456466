<template>
  <div class="sponsor-container">
    <el-page-header
      @back="goBack"
      style="position: fixed; top: 20px; left: 40px"
    >
    </el-page-header>
    <h1 class="title">支持我们</h1>
    <div class="title-hr"></div>
    <h4 class="title-describe">{{ global.sponsorTitle }}</h4>
    <div class="img-container">
      <img src="../assets/wx.jpg" />
      <img src="../assets/zfb.jpg" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      global: {}
    }
  },
  created: function () {
    this.$http.get('/api/global').then((response) => {
      this.global = response.data
    })
  },
  methods: {
    goBack() {
      this.$router.push('/')
    }
  }
}
</script>

<style>
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 80px;
}

.img-container > img {
  width: 300px;
  margin: 40px 60px 0px;
}

.el-page-header__title {
  font-size: 17px;
  font-weight: 600;
}
</style>
