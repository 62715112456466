<template>
  <div class="main">
    <div class="header" ref="header">
      <div class="header-left" ref="headerLeft">
        <img class="header-logo" src="../assets/logo2.jpg" />
        <p class="header-title">空管模拟机交流群官网</p>
      </div>
      <div class="header-right">
        <el-link :href="Qqun" style="color: aliceblue" :underline="false"
          >加入我们</el-link
        >
        <el-link
          :href="Sys"
          target="_blank"
          style="color: aliceblue"
          :underline="false"
          >空管模拟机服务系统</el-link
        >
        <router-link to="/sponsor" target="_blank" style="text-decoration: none"
          ><el-link style="color: aliceblue" :underline="false"
            >支持我们</el-link
          ></router-link
        >
        <el-link
          style="color: aliceblue"
          :underline="false"
          target="_blank"
          :href="More"
          >更多服务</el-link
        >
        <router-link to="/partners" target="_blank"
          ><el-link style="color: aliceblue" :underline="false"
            >合作伙伴</el-link
          ></router-link
        >
      </div>
      <div class="el-icon-more" @click="drawer = true"></div>
    </div>
    <el-drawer
      class="drawer"
      title="more"
      :visible.sync="drawer"
      :with-header="false"
    >
      <el-link
        :href="Qqun"
        style="color: aliceblue"
        :underline="false"
        class="drawer-link"
        >加入我们</el-link
      >
      <el-link
        :href="Sys"
        target="_blank"
        style="color: aliceblue"
        :underline="false"
        class="drawer-link"
        >空管模拟机服务系统</el-link
      >
      <router-link to="/sponsor" target="_blank" style="text-decoration: none"
        ><el-link
          style="color: aliceblue"
          :underline="false"
          class="drawer-link"
          >支持我们</el-link
        ></router-link
      >
      <el-link
        style="color: aliceblue"
        :underline="false"
        class="drawer-link"
        target="_blank"
        :href="More"
        >更多服务</el-link
      >
      <router-link to="/partners" target="_blank"
        ><el-link
          class="drawer-link"
          style="color: aliceblue"
          :underline="false"
          >合作伙伴</el-link
        ></router-link
      >
    </el-drawer>
    <div class="banner">
      <h1>空管模拟机交流群官网</h1>
      <h1>ATC Simulation Club</h1>
      <h3>学习者来自世界各地</h3>
      <!-- <img class="banner-logo" src="../assets/logo2.jpg" /> -->
    </div>
    <div class="features">
      <div class="features-title" ref="featuresTitle">
        <h1 class="title">本群特点</h1>
        <div class="title-hr"></div>
        <h4 class="title-describe">{{ global.featuresTitle }}</h4>
      </div>
      <div class="features-container">
        <feature
          v-for="featrue in features"
          :key="featrue.id"
          :name="featrue.title"
          :detail="featrue.detail"
        ></feature>
      </div>
    </div>
    <div class="intros">
      <div class="intros-title" ref="introsTitle">
        <h1 class="title">群简介</h1>
        <div class="title-hr"></div>
        <h4 class="title-describe">{{ global.introsTitle }}</h4>
      </div>
      <div class="intros-container">
        <div class="intros-intro" ref="introsIntro" style="position: relative">
          <P>{{ global.introsIntro }}</P>
        </div>
        <div class="intros-copy" ref="introsCopy">
          <div class="intros-copy-container">
            <p class="intros-copy-one" v-for="intro in intros" :key="intro.id">
              {{ intro.copy }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="originator">
      <div class="originator-title" ref="originatorTitle">
        <h1 class="title">创始人</h1>
        <div class="title-hr"></div>
        <h4 class="title-describe">{{ global.originatorTitle }}</h4>
      </div>
      <div class="originator-container">
        <user
          :qq="originator.qq"
          :number="originator.number"
          :intro="originator.intro"
        ></user>
      </div>
    </div>
    <div class="honor-list">
      <div class="honor-list-title" ref="honorListTitle">
        <h1 class="title">名誉榜</h1>
        <div class="title-hr"></div>
        <h4 class="title-describe">{{ global.honorListTitle }}</h4>
      </div>
      <div class="honor-container">
        <div
          style="min-width: 50%; margin: 0 auto"
          v-for="user in users"
          :key="user.id"
        >
          <user :qq="user.qq" :number="user.number" :intro="user.intro"></user>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-container">
        <div class="join-us">
          <div class="title2">加入我们</div>
          <div class="title-hr2"></div>
          <div class="join-us-container">
            <a :href="Qqun" target="_blank"
              ><el-button
                class="Join-us-botton"
                :round="true"
                style="background-color: rgb(255, 224, 183)"
                >Join US</el-button
              ></a
            >
          </div>
        </div>
        <div class="links">
          <div class="title2">友情链接</div>
          <div class="title-hr2"></div>
          <div class="links-container">
            <footerLink
              v-for="link in links"
              :key="link.id"
              :title="link.title"
              :url="link.url"
            ></footerLink>
          </div>
        </div>
        <div class="contact-us">
          <div class="title2">联系我们</div>
          <div class="title-hr2"></div>
          <div class="contact-us-container">
            <p>QQ群：949076443</p>
            <p>创始人QQ：3376456371</p>
          </div>
        </div>
      </div>
      <div class="copyright">
        <p>{{ global.copyright }}</p>
        <p>
          <a class="record" href="https://beian.miit.gov.cn/">{{
            global.record
          }}</a>
        </p>
      </div>
    </div>
    <div class="to-top">
      <el-backtop
        style="color: rgb(255, 224, 183); background-color: #666666"
      ></el-backtop>
    </div>
  </div>
</template>
<script>
import feature from '@/components/feature'
import user from '@/components/user'
import footerLink from '@/components/footerLink'
export default {
  components: {
    feature,
    user,
    footerLink
  },
  data() {
    return {
      Qqun: 'https://qm.qq.com/cgi-bin/qm/qr?k=ojo9beHfgehjO6GTuaggXRgDD34b8Akp&jump_from=webapi&authKey=J1dvi543sjhWj962x8m8fWoGklIw/nwdjTTobdwyC1yxSSEhYTnKbd0uKk2840Ea',
      Sys: 'http://system.flyatcsim.club',
      More: 'https://mbd.pub/o/liu2005',
      drawer: false,
      features: [],
      global: {},
      intros: [],
      links: [],
      users: [],
      originator: {}
    }
  },
  created: function () {
    this.$http.get('/api/global').then((response) => {
      this.global = response.data
    })

    this.$http.get('/api/features').then((response) => {
      this.features = response.data
    })

    this.$http.get('/api/intros').then((response) => {
      this.intros = response.data
    })

    this.$http.get('/api/links').then((response) => {
      this.links = response.data
    })

    this.$http.get('/api/users/one').then((response) => {
      this.originator = response.data
    })

    this.$http.get('/api/users/all').then((response) => {
      this.users = response.data
    })
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (scrollTop > 100) {
        this.$refs.header.classList.add('show')
        this.$refs.headerLeft.classList.add('show')
        this.$refs.featuresTitle.classList.add('show')
      } else {
        this.$refs.header.classList.remove('show')
        this.$refs.headerLeft.classList.remove('show')
      }

      const rule = (window.innerHeight / 10) * 9

      const scList = []
      scList[0] = this.$refs.introsTitle
      scList[1] = this.$refs.originatorTitle
      scList[2] = this.$refs.honorListTitle
      scList[3] = this.$refs.introsIntro
      scList[4] = this.$refs.introsCopy
      for (var i = 0; i < scList.length; i++) {
        const dist = scList[i].getBoundingClientRect().top
        if (dist < rule) {
          scList[i].classList.add('show')
        }
      }

      const boxList = []
      boxList[0] = document.querySelectorAll('.user-container')
      boxList[1] = document.querySelectorAll('.feature-container')
      for (var i = 0; i < boxList.length; i++) {
        boxList[i].forEach((box) => {
          const boxTop = box.getBoundingClientRect().top
          if (boxTop < rule) {
            box.classList.add('show')
          }
        })
      }
    }
  }
}
</script>
<style>
.header {
  position: fixed;
  display: flex;
  justify-content: flex-start;
  background-color: #00000000;
  color: rgb(255, 255, 255);
  line-height: 40px;
  width: 100%;
  z-index: 3;
  padding: 0 12px;
  transition: background-color 0.5s ease;
}

.header.show {
  background-color: #000000;
}

.header-left {
  display: flex;
  opacity: 0;
  transition: opacity 0.5s ease;
  height: 40px;
}

.header-left.show {
  opacity: 1;
}

.header-title {
  font-size: 20px;
  margin-left: 10px;
  line-height: 40px;
}

.header-logo {
  height: 30px;
  width: 30px;
  margin: 5px 0;
}

.header-right {
  margin-left: auto;
}

.el-link {
  margin: 0px 5px;
}

.el-icon-more {
  line-height: 40px;
  font-size: 30px;
  cursor: pointer;
  visibility: hidden;
  position: absolute;
}

.el-drawer.rtl {
  background-color: #000000c2;
  color: #ffffff;
  width: 40%;
}

.el-drawer__body {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 60px 0 0 20px;
}

.banner {
  height: 100vh;
  width: 100%;
  background-image: url('../assets/bk.png');
  background-size: cover;
  background-position: center top;
  color: #ffffff;
  position: relative;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.banner > h1,
h3 {
  display: block;
  width: 100%;
  line-height: 85px;
  text-align: center;
  margin-bottom: 4px;
  margin-left: 30px;
  font-weight: 400;
  letter-spacing: 5px;
}

.banner > h1:nth-child(1) {
  font-size: 60px;
}

.banner > h1:nth-child(2) {
  font-size: 65px;
}

.banner > h3 {
  font-size: 35px;
}

.banner-logo {
  width: 200px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  visibility: hidden;
}

.title {
  text-align: center;
  margin: 60px 0 0 0;
  letter-spacing: 2px;
}

.title-hr {
  height: 1px;
  width: 120px;
  margin: 0 auto;
  background-color: #dddddd;
  margin-top: 18px;
  position: relative;
}

.title-hr::after {
  content: '';
  height: 4px;
  width: 40px;
  background-color: rgb(255, 224, 183);
  position: absolute;
  left: 40px;
  bottom: -1px;
}

.title-describe {
  width: 60%;
  text-align: center;
  font-size: 16px;
  letter-spacing: 2px;
  color: #666666;
  margin: 15px auto 0;
}

.features-title,
.intros-title,
.originator-title,
.honor-list-title,
.user-container,
.intros-intro,
.intros-copy,
.feature-container {
  position: relative;
  top: 150px;
  opacity: 0;
}

.features-title.show,
.intros-title.show,
.originator-title.show,
.honor-list-title.show,
.intros-intro.show,
.user-container.show,
.intros-copy.show {
  opacity: 1;
  transform: translateY(-150px);
  transition: transform 0.6s ease;
}

@keyframes kfshow {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  50% {
    transform: translateY(-160px);
  }
  80% {
    transform: translateY(-140px);
  }
  100% {
    transform: translateY(-150px);
    opacity: 1;
  }
}

.feature-container.show {
  animation: kfshow 0.6s ease forwards;
}

@keyframes scroll_show {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.intros-copy-container {
  width: auto;
  height: auto;
  min-height: 100%;
  animation: scroll_show 16s infinite linear;
}

.intros-copy-one {
  font-size: 16px;
  margin: 20px 5px;
  color: #999999;
  text-align: center;
}

.features-container {
  width: 100%;
  display: flex;
  padding: 40px 60px 0;
  flex-wrap: wrap;
}

.intros-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 40px 40px 0;
}

.intros-intro,
.intros-copy {
  flex-shrink: 1;
  height: 350px;
  width: 560px;
  background-color: #0000000f;
  margin: 0 auto 40px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.intros-intro > p {
  font-size: 18px;
  letter-spacing: 5px;
  text-align: center;
  color: #727272;
  line-height: 35px;
  text-indent: 2em;
}

.originator-container {
  padding: 40px 100px 0;
}

.honor-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 40px 160px 0;
}

.footer {
  background-color: #000000;
  color: rgb(255, 255, 255);
}

.footer-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0 0px;
  flex-wrap: wrap;
}

.join-us,
.links,
.contact-us {
  width: 240px;
  height: 160px;
  margin: 0 20px 40px;
}

.title2 {
  font-size: 15px;
  font-weight: bold;
}

.title-hr2 {
  height: 1.5px;
  background-color: #5d5d5d;
  width: 100%;
  margin: 10px 0 15px;
  position: relative;
}

.title-hr2::after {
  content: '';
  width: 56px;
  height: 1.5px;
  background-color: rgb(255, 224, 183);
  position: absolute;
}

.Join-us-botton {
  color: #000000;
  border-color: #000000;
}

.Join-us-botton:focus,
.Join-us-botton:hover {
  color: #666666;
  border-color: #000000;
}

.links-container {
  height: 120px;
  overflow-y: auto;
}

.links-container::-webkit-scrollbar {
  width: 0;
}

.contact-us-container {
  font-size: 14px;
  line-height: 35px;
}

.copyright {
  text-align: center;
  font-size: 14px;
  line-height: 25px;
  padding-bottom: 10px;
}

.record:link {
  color: rgb(255, 230, 199);
}

a:link {
  color: #ffffff;
}

a:visited {
  color: #ffffff;
}

a {
  text-decoration: none;
}

el-backtop {
  color: rgb(255, 224, 183);
}

.el-icon-caret-top:before {
  content: '\e78f';
}

@media screen and (max-width: 750px) {
  .header-title {
    font-size: 16px;
  }

  .header-right {
    visibility: hidden;
    position: absolute;
  }

  .el-icon-more {
    visibility: visible;
    right: 14px;
  }

  .drawer-link {
    font-size: 13px;
    font-weight: 600;
    margin: 15px 6px;
  }

  .features-container,
  .intros-container,
  .originator-container,
  .honor-container,
  .footer-container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .banner {
    padding-top: 0px;
  }

  .banner > h1,
  h3 {
    line-height: 45px;
    margin-bottom: 4px;
    margin-left: 0;
    font-weight: 400;
    letter-spacing: 3px;
  }

  .banner > h1:nth-child(1) {
    font-size: 30px;
  }

  .banner > h1:nth-child(2) {
    font-size: 35px;
  }

  .banner > h3 {
    font-size: 20px;
  }

  .banner-logo {
    width: 180px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    visibility: visible;
  }

  .user-container {
    width: 300px;
    height: 460px;
  }

  .portrait {
    display: block;
    width: 240px;
    margin: 10px auto 20px;
  }

  .user-intro {
    padding: 0 10px;
    margin-top: 6px;
  }

  .title-describe {
    width: 90%;
  }
}
</style>
